
import BuildingsBox from '@/components/overview/BuildingsBox.vue'
import CategoriesBox from '@/components/overview/CategoriesBox.vue'
import FractionsBox from '@/components/overview/FractionsBox.vue'
import ProjectBox from '@/components/overview/ProjectBox.vue'
import RemediationBox from '@/components/overview/RemediationBox.vue'
import ResaleBox from '@/components/overview/ResaleBox.vue'
import SamplesBox from '@/components/overview/SamplesBox.vue'
import ProSample from '@/globals/javascript/models/ProSample/ProSample'
import { mixWB } from '@/globals/javascript/_utils/mixins'
import { ICategoryGroup } from '@/__types__/categoryGroup'
import { IFractionGroup } from '@/__types__/fractionGroup'
import {
  ICategoriesBoxData,
  IFractionBoxData,
  IRemediationBoxData,
  IResaleBoxData,
  ISamplesBoxData,
  IUnitsBoxData,
} from '@/__types__/overview'
import { currentStore } from '@/store'
import { orderBy } from 'lodash-es'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'
import FilterButton from '@/components/filter/FilterButton.vue'

export default defineComponent({
  name: 'Overview',
  computed: {
    ...mapState(currentStore, {
      project: 'project',
      account: 'account',
      filterIsActive: 'buildingFilterApplied',
      selectedBuilding: ({ unitsByBuilding }) =>
        unitsByBuilding.active.buildings[0]?.getFullTitle() ?? '',
      fractions: ({ fractionGroupsByBuilding }): IFractionBoxData => {
        // Order groups in desc cost
        let fractions = orderBy(
          fractionGroupsByBuilding.all,
          [(x: IFractionGroup) => x.tons.total],
          'desc'
        )

        const costPresent = fractions.some((x) => x.tons.total)

        // If any has cost present, filter away those without
        if (costPresent) {
          fractions = fractions.filter((x) => x.tons.total)
        }

        let title = ''

        if (!fractionGroupsByBuilding.all.length) {
          title = mixWB('VIEW_FRACTIONS')
        } else if (fractionGroupsByBuilding.all.length === 1) {
          title = mixWB(`VIEW_FRACTION`)
        } else {
          title = mixWB(`VIEW_ALL_X_FRACTIONS`, [
            fractionGroupsByBuilding.all.length.toString(),
          ])
        }

        return {
          all: fractionGroupsByBuilding.all,
          list: fractions.slice(0, 3) as IFractionGroup[],
          title,
        }
      },
      remediation: ({ remediationGroupsByBuilding }): IRemediationBoxData => {
        const { withoutCleaning, withCleaning, potential } =
          remediationGroupsByBuilding

        let withCleaningText = ''
        let withoutCleaningText = ''
        let potentialText = ''

        if (withCleaning.length) {
          withCleaningText =
            withCleaning.length === 1
              ? mixWB('1_TASK_WITH_CLEANING')
              : mixWB('X_TASKS_WITH_CLEANING', [withCleaning.length.toString()])
        }

        if (withoutCleaning.length) {
          withoutCleaningText =
            withoutCleaning.length === 1
              ? mixWB('1_TASK_WITHOUT_CLEANING')
              : mixWB('X_TASKS_WITHOUT_CLEANING', [
                  withoutCleaning.length.toString(),
                ])
        }

        if (potential.length) {
          potentialText =
            potential.length === 1
              ? mixWB('1_POTENTIAL_TASK')
              : mixWB('X_POTENTIAL_TASKS', [potential.length.toString()])
        }

        const noTasks =
          !withCleaningText && !withoutCleaningText && !potentialText

        return {
          noTasks,
          withCleaningText,
          withoutCleaningText,
          potentialText,
        }
      },
      resale: ({ resaleByBuilding }): IResaleBoxData => {
        const { normal, highlighted } = resaleByBuilding

        const hasHighlighted = !!highlighted.length

        const listItems = hasHighlighted ? highlighted : normal

        let subheader = ''

        if (highlighted.length) {
          if (highlighted.length > 3) {
            subheader = mixWB('X_OF_Y_FEATURED_RESALE_ITEMS', [
              '3',
              highlighted.length.toString(),
            ])
          } else if (highlighted.length > 1) {
            subheader = mixWB('X_FEATURED_RESALE_ITEMS', [
              highlighted.length.toString(),
            ])
          } else if (highlighted.length === 1) {
            subheader = mixWB('1_FEATURED_RESALE_ITEM')
          }
        }

        if (normal.length && !highlighted.length) {
          if (normal.length > 3) {
            subheader = mixWB('X_OF_Y_RESALE_ITEMS', [
              '3',
              normal.length.toString(),
            ])
          } else if (normal.length > 1) {
            subheader = mixWB('X_RESALE_ITEMS', [normal.length.toString()])
          } else if (normal.length === 1) {
            subheader = mixWB('1_RESALE_ITEM')
          }
        }

        return {
          list: listItems.slice(0, 3),
          subheader,
        }
      },
      categories: ({ categoryGroupsByBuilding }): ICategoriesBoxData => {
        const groupsWithTypes = categoryGroupsByBuilding.withOnlineTypes

        const listItems = orderBy(
          groupsWithTypes,
          [(x: ICategoryGroup) => x.onlineTypes.allRelated.length],
          ['desc']
        ).slice(0, 3)

        const totalTypes = groupsWithTypes.reduce((sum, group) => {
          sum += group.onlineTypes.allRelated.length
          return sum
        }, 0)

        const typeCount =
          totalTypes === 1
            ? mixWB('1_TYPE')
            : mixWB('X_TYPES', [totalTypes.toString()])
        const categoryCount =
          groupsWithTypes.length === 1
            ? mixWB('1_BUILDING_PART')
            : mixWB('X_BUILDING_PARTS', [groupsWithTypes.length.toString()])
        const metaText = mixWB('X_DIVIDED_INTO_Y', [typeCount, categoryCount])

        return {
          all: groupsWithTypes,
          list: listItems,
          metaText,
        }
      },
      samples: ({ samplesByBuilding }): ISamplesBoxData => {
        const sampleTypeText = (
          arr: ProSample[],
          single: string,
          multiple: string
        ) => {
          const text =
            arr.length === 0
              ? ''
              : `${arr.length} ${
                  arr.length === 1 ? mixWB(single) : mixWB(multiple)
                }`

          return text.toLowerCase()
        }

        // Text for types
        const samplesText = sampleTypeText(
          samplesByBuilding.standard as ProSample[],
          'SAMPLE',
          'SAMPLES'
        )

        const assessed = sampleTypeText(
          samplesByBuilding.assessed as ProSample[],
          'ASSESSED_SAMPLE',
          'ASSESSED_SAMPLES'
        )
        const existing = sampleTypeText(
          samplesByBuilding.existing as ProSample[],
          'EXISTING_SAMPLE',
          'EXISTING_SAMPLES'
        )
        const postponed = sampleTypeText(
          samplesByBuilding.postponed as ProSample[],
          'POSTPONED_SAMPLE',
          'POSTPONED_SAMPLES'
        )

        let metaText = [samplesText, assessed, existing]
          .filter(Boolean)
          .join(' - ')

        const results = samplesByBuilding.all.reduce(
          (prev, sample) => {
            if (sample.sampleType === 'equivalent') {
              return prev
            }

            if (sample.options.hasMissingTestResult && !sample.postponeNumber) {
              prev.missing += 1

              return prev
            }

            const resultClass = sample.getOverallResultClass()

            if (!resultClass) {
              return prev
            }

            const resultClassLowerCase = resultClass.toLowerCase()

            prev[resultClassLowerCase as keyof typeof prev] += 1

            return prev
          },
          { clean: 0, contaminated: 0, hazardous: 0, missing: 0 }
        )

        // Text for result
        const clean =
          results.clean === 0
            ? mixWB('NO_CLEAN_SAMPLES')
            : results.clean === 1
            ? mixWB('1_SAMPLE_IS_CLEAN')
            : mixWB('X_SAMPLES_ARE_CLEAN', [results.clean.toString()])

        const contaminated =
          results.contaminated === 0
            ? mixWB('NO_CONTAMINATED_SAMPLES')
            : results.contaminated === 1
            ? mixWB('1_SAMPLE_IS_CONTAMINATED')
            : mixWB('X_SAMPLES_ARE_CONTAMINATED', [
                results.contaminated.toString(),
              ])

        const hazardous =
          results.hazardous === 0
            ? mixWB('NO_HAZARDOUS_SAMPLES')
            : results.hazardous === 1
            ? mixWB('1_SAMPLE_IS_HAZARDOUS')
            : mixWB('X_SAMPLES_ARE_HAZARDOUS', [results.hazardous.toString()])

        const missing =
          results.missing === 0
            ? ''
            : results.missing === 1
            ? mixWB('1_SAMPLE_HAS_MISSING_TEST_RESULTS')
            : mixWB('X_SAMPLES_ARE_MISSING_TEST_RESULTS', [
                results.missing.toString(),
              ])

        return {
          all: samplesByBuilding.all,
          metaText,
          recap: {
            clean,
            contaminated,
            hazardous,
            postponed,
            missing,
          },
        }
      },
      units: ({ unitsByBuilding }): IUnitsBoxData => {
        const { selected, active } = unitsByBuilding

        const activeBuildings = orderBy(
          active.buildings,
          'bbrData.Bygningsnr',
          'asc'
        )

        const metaBuildings = !selected.buildings.length
          ? ''
          : selected.buildings.length === 1
          ? `1 ${mixWB('BUILDING')}`
          : mixWB('X_BUILDINGS', [selected.buildings.length.toString()])

        const metaFloors = !selected.floors.length
          ? ''
          : selected.floors.length === 1
          ? `1 ${mixWB('FLOOR')}`
          : `${selected.floors.length} ${mixWB('FLOORS_2')}`

        const metaApartments = !selected.apartments.length
          ? ''
          : selected.apartments.length === 1
          ? `1 ${mixWB('APARTMENT')}`
          : mixWB('X_APARTMENTS', [selected.apartments.length.toString()])

        const metaText = [metaBuildings, metaFloors, metaApartments]
          .filter(Boolean)
          .join(' - ')

        return {
          list: activeBuildings.slice(0, 3),
          meta: metaText.toLowerCase(),
        }
      },
    }),
  },
  components: {
    ProjectBox,
    ResaleBox,
    FractionsBox,
    BuildingsBox,
    CategoriesBox,
    SamplesBox,
    RemediationBox,
    FilterButton,
  },
})
