
import { currentStore, filterStore } from '@/store'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'FilterButton',
  computed: {
    ...mapState(filterStore, ['isOpen', 'isActive']),
    ...mapState(currentStore, ['isEnterpriseProject']),
  },
  methods: {
    ...mapActions(filterStore, ['setIsOpen']),
  },
})
