import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-62e58f8a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "Overview CustomScrollbar" }
const _hoisted_2 = { class: "OverviewContent" }
const _hoisted_3 = { class: "Col1" }
const _hoisted_4 = {
  key: 0,
  class: "FilterActiveMessage"
}
const _hoisted_5 = { class: "Col2" }
const _hoisted_6 = { class: "Col3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FilterButton = _resolveComponent("FilterButton")!
  const _component_ProjectBox = _resolveComponent("ProjectBox")!
  const _component_FractionsBox = _resolveComponent("FractionsBox")!
  const _component_RemediationBox = _resolveComponent("RemediationBox")!
  const _component_ResaleBox = _resolveComponent("ResaleBox")!
  const _component_BuildingsBox = _resolveComponent("BuildingsBox")!
  const _component_CategoriesBox = _resolveComponent("CategoriesBox")!
  const _component_SamplesBox = _resolveComponent("SamplesBox")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_ctx.filterIsActive)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("span", null, _toDisplayString(_ctx.mixWB('SHOWING_ONLY_X', [_ctx.selectedBuilding])), 1),
              _createVNode(_component_FilterButton, { class: "ChangeProject" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.mixWB('SELECT')), 1)
                ]),
                _: 1
              })
            ]))
          : _createCommentVNode("", true),
        _createVNode(_component_ProjectBox, {
          "keep-alive": "",
          project: _ctx.project,
          account: _ctx.account
        }, null, 8, ["project", "account"]),
        _createVNode(_component_FractionsBox, { data: _ctx.fractions }, null, 8, ["data"]),
        _createVNode(_component_RemediationBox, { data: _ctx.remediation }, null, 8, ["data"]),
        _createVNode(_component_ResaleBox, { data: _ctx.resale }, null, 8, ["data"]),
        _createVNode(_component_BuildingsBox, { data: _ctx.units }, null, 8, ["data"]),
        _createVNode(_component_CategoriesBox, { data: _ctx.categories }, null, 8, ["data"]),
        _createVNode(_component_SamplesBox, { data: _ctx.samples }, null, 8, ["data"])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_FractionsBox, { data: _ctx.fractions }, null, 8, ["data"]),
        _createVNode(_component_RemediationBox, { data: _ctx.remediation }, null, 8, ["data"]),
        _createVNode(_component_BuildingsBox, { data: _ctx.units }, null, 8, ["data"]),
        _createVNode(_component_ResaleBox, { data: _ctx.resale }, null, 8, ["data"]),
        _createVNode(_component_CategoriesBox, { data: _ctx.categories }, null, 8, ["data"]),
        _createVNode(_component_SamplesBox, { data: _ctx.samples }, null, 8, ["data"])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_ResaleBox, { data: _ctx.resale }, null, 8, ["data"]),
        _createVNode(_component_CategoriesBox, { data: _ctx.categories }, null, 8, ["data"]),
        _createVNode(_component_SamplesBox, { data: _ctx.samples }, null, 8, ["data"])
      ])
    ])
  ]))
}