import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0b451f36"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "DefaultContent" }
const _hoisted_2 = {
  key: 0,
  class: "State"
}
const _hoisted_3 = {
  key: 0,
  class: "Label"
}
const _hoisted_4 = { class: "Label" }
const _hoisted_5 = { class: "Button" }
const _hoisted_6 = { class: "Label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InlineSVG = _resolveComponent("InlineSVG")!

  return (_openBlock(), _createElementBlock("button", {
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setIsOpen())),
    class: _normalizeClass(["FilterButton", {'IsOpen': _ctx.isOpen, 'IsActive': _ctx.isActive}])
  }, [
    _renderSlot(_ctx.$slots, "default", {
      isOpen: _ctx.isOpen,
      isActive: _ctx.isActive
    }, () => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.isActive)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              (_ctx.isEnterpriseProject)
                ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.mixWB('1_PROJECT')), 1))
                : _createCommentVNode("", true),
              _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.mixWB('1_UNIT')), 1)
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_5, [
          (_ctx.isOpen)
            ? (_openBlock(), _createBlock(_component_InlineSVG, {
                key: 0,
                class: "Icon",
                src: require('@/assets/svg/close.svg')
              }, null, 8, ["src"]))
            : (_openBlock(), _createBlock(_component_InlineSVG, {
                key: 1,
                class: "Icon",
                src: require('@/assets/svg/filter.svg')
              }, null, 8, ["src"])),
          _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.mixWB('FILTER')), 1)
        ])
      ])
    ], true)
  ], 2))
}