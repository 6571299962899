import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withCtx as _withCtx, withDirectives as _withDirectives, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-bf1b426e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ProjectBox" }
const _hoisted_2 = { class: "ProjectImageFallback" }
const _hoisted_3 = { class: "ProjectRow ProjectDetails" }
const _hoisted_4 = { class: "Title" }
const _hoisted_5 = { class: "Caption" }
const _hoisted_6 = { class: "ProjectRow ProjectMeta" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { key: 3 }
const _hoisted_10 = {
  key: 0,
  class: "ProjectRow ProjectMeta"
}
const _hoisted_11 = {
  key: 1,
  class: "ProjectRow ProjectMeta"
}
const _hoisted_12 = {
  key: 2,
  class: "ProjectRow ProjectMeta"
}
const _hoisted_13 = {
  key: 3,
  class: "ProjectRow ProjectMeta"
}
const _hoisted_14 = {
  key: 4,
  class: "ProjectRow ProjectMeta"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InlineSVG = _resolveComponent("InlineSVG")!
  const _component_Image = _resolveComponent("Image")!
  const _directive_gallery = _resolveDirective("gallery")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createVNode(_component_Image, {
      class: "ProjectImage",
      image: _ctx.project.getProfileImage() ?? null,
      dimensions: {
        height: 400,
        width: 400,
      },
      transformation: 'square_400'
    }, {
      fallback: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_InlineSVG, {
            src: require('@/assets/svg/image-placeholder.svg')
          }, null, 8, ["src"]),
          _createElementVNode("span", null, _toDisplayString(_ctx.mixWB('PICTURE_NOT_YET_TAKEN')), 1)
        ])
      ]),
      _: 1
    }, 8, ["image"]), [
      [_directive_gallery, 'projectBox']
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("address", _hoisted_4, _toDisplayString(_ctx.projectAddress), 1),
      _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.mixWB('CASE_NUMBER')) + ": " + _toDisplayString(_ctx.project.getCaseNumber()), 1),
      _createElementVNode("span", {
        class: _normalizeClass(["Status", _ctx.project.status.className])
      }, _toDisplayString(_ctx.project.status.text ?? ''), 3)
    ]),
    _createElementVNode("div", _hoisted_6, [
      (_ctx.project.status.code === 'report-ready')
        ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.project.getProjectDoneByText()) + ":", 1))
        : (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_ctx.project?.getProjectBeingConductedByText()) + ":", 1)),
      (_ctx.account.logo)
        ? (_openBlock(), _createBlock(_component_Image, {
            key: 2,
            path: _ctx.account.logo,
            transformation: 'overview_account_logo'
          }, null, 8, ["path"]))
        : (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(_ctx.account.name), 1))
    ]),
    (_ctx.project.status.code === 'not-started')
      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
          _createElementVNode("span", null, _toDisplayString(_ctx.mixWB('PROJECT_CREATED')) + ":", 1),
          _createElementVNode("span", null, _toDisplayString((_ctx.project && _ctx.mixGetDate(_ctx.project.data.createdAt)) || '-'), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.project.status.code === 'ongoing')
      ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
          _createElementVNode("span", null, _toDisplayString(_ctx.project?.getProjectStartingDateText()) + ":", 1),
          _createElementVNode("span", null, _toDisplayString((_ctx.project && _ctx.mixGetDate(_ctx.project.data.screeningStartTime)) || '-'), 1)
        ]))
      : _createCommentVNode("", true),
    (['awaiting-test-results', 'report-ready', 'under-revision'].includes(_ctx.project.status.code))
      ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
          _createElementVNode("span", null, _toDisplayString(_ctx.project?.getProjectDateText()) + ":", 1),
          _createElementVNode("span", null, _toDisplayString(_ctx.mappingDate), 1)
        ]))
      : _createCommentVNode("", true),
    (['report-ready', 'under-revision'].includes(_ctx.project.status.code))
      ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
          _createElementVNode("span", null, _toDisplayString(_ctx.mixWB('REPORTING_DATE')) + ":", 1),
          _createElementVNode("span", null, _toDisplayString((_ctx.project && _ctx.mixGetDate(_ctx.project.data.screeningReportDeliveredDate)) || '-'), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.project.data?.screeningRevisedDate)
      ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
          _createElementVNode("span", null, _toDisplayString(_ctx.mixWB('DATE_OF_REVISION')) + ":", 1),
          _createElementVNode("span", null, _toDisplayString((_ctx.project && _ctx.mixGetDate(_ctx.project.data.screeningRevisedDate)) || '-'), 1)
        ]))
      : _createCommentVNode("", true)
  ]))
}