
import OverviewBox from '@/components/overview/OverviewBox.vue'
import { IRemediationBoxData } from '@/__types__/overview'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'RemediationBox',
  props: {
    data: {
      type: Object as PropType<IRemediationBoxData>,
      required: true,
    },
  },
  components: { OverviewBox },
})
