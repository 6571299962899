
import Image from '@/components/Image.vue'
import { ProProject } from '@/globals/javascript/models/ProProject/ProProject'
import { currentStore } from '@/store'
import { IProRawProjectAccount } from '@/__types__/_pro/raw-project/pro-raw-project-account'
import { mapState } from 'pinia'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'ProjectBox',
  props: {
    project: {
      type: Object as PropType<ProProject>,
      required: true,
    },
    account: {
      type: Object as PropType<IProRawProjectAccount>,
      required: true,
    },
  },
  computed: {
    ...mapState(currentStore, ['projectAddress']),
    mappingDate() {
      let start = this.project.data.screeningStartTime
      let end = this.project.data.screeningEndTime

      if (!start || !end) {
        return '-'
      }

      const startFormatted = this.mixGetDate(start)
      const endFormatted = this.mixGetDate(end)

      if (startFormatted === endFormatted) {
        return startFormatted
      }

      return `${startFormatted} - ${endFormatted}`
    },
  },
  components: { Image },
})
