import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-563cff0e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "DefaultWrap" }
const _hoisted_2 = {
  key: 0,
  class: "Arrow SideRight"
}
const _hoisted_3 = {
  key: 1,
  class: "Arrow SideLeft"
}
const _hoisted_4 = {
  key: 2,
  class: "Arrow BelowRight"
}
const _hoisted_5 = {
  key: 3,
  class: "Arrow BelowLeft"
}
const _hoisted_6 = { class: "Text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Backdrop = _resolveComponent("Backdrop")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["ToolTip", {
      HasMessage: _ctx.hasMessage,
      IsActive: _ctx.showToolTipByClick || _ctx.showToolTipByHover,
    }]),
    onClick: [
      _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onToolTipClick && _ctx.onToolTipClick(...args)), ["stop"])),
      _cache[1] || (_cache[1] = _withModifiers(() => {}, ["prevent"]))
    ],
    onMouseenter: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onToolTipMouseEnter && _ctx.onToolTipMouseEnter(...args))),
    onMouseleave: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onToolTipMouseLeave && _ctx.onToolTipMouseLeave(...args)))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ]),
    (_ctx.showBackDrop)
      ? (_openBlock(), _createBlock(_component_Backdrop, {
          key: 0,
          "z-index": 1
        }))
      : _createCommentVNode("", true),
    (_ctx.showToolTip)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          ref: "Message",
          class: _normalizeClass(["Message", _ctx.toolTipClasses])
        }, [
          (_ctx.placement === 'side' && _ctx.useLeftSide)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2))
            : _createCommentVNode("", true),
          (_ctx.placement === 'side' && !_ctx.useLeftSide)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3))
            : _createCommentVNode("", true),
          (_ctx.placement === 'below' && _ctx.useLeftSide)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4))
            : _createCommentVNode("", true),
          (_ctx.placement === 'below' && !_ctx.useLeftSide)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_6, [
            _renderSlot(_ctx.$slots, "message", {}, undefined, true)
          ])
        ], 2))
      : _createCommentVNode("", true)
  ], 34))
}