
import Spinner from '@/components/Spinner.vue'
import { IImage } from '@/__types__/_pro/partials/image'
import { pdfStore } from '@/store'
import { mapActions } from 'pinia'
import { defineComponent, PropType } from 'vue'
import { HOST } from '@/globals/javascript/_utils/host'
import { ENV } from '@/globals/javascript/_utils/env'

export default defineComponent({
  name: 'Image',
  props: {
    image: {
      type: Object as PropType<IImage | null>,
      required: false,
    },
    path: {
      type: String,
      required: false,
    },
    dimensions: {
      type: Object as PropType<{ height: number; width: number }>,
      default: () => ({ width: NaN, height: NaN }),
    },
    imgStyle: {
      type: Object,
      default: () => ({}),
    },
    transformation: {
      type: String as PropType<
        | 'default'
        | 'resize_min_240'
        | 'square_40'
        | 'square_80'
        | 'square_200'
        | 'square_360'
        | 'square_400'
        | 'overview_account_logo'
      >,
      required: true,
    },
    loading: {
      type: String as PropType<'eager' | 'lazy'>,
      default: 'lazy',
    },
    context: {
      type: String,
      default: 'online',
    },
  },
  data() {
    if (
      ENV.isDevelopment &&
      this.image === undefined &&
      this.path === undefined
    ) {
      throw new Error('Image.vue: property image OR path MUST be set')
    }

    return {
      src: '',
      isLoading: true,
      useFallback: false,
    }
  },
  computed: {
    useDefaultMinHeight(): boolean {
      return !this.dimensions.height
    },
  },
  methods: {
    ...mapActions(pdfStore, ['addAsPending', 'removeAsPending']),
    async loadImage() {
      const path = this.path || this.image?.base?.path

      if (!path || !this.transformation) {
        this.useFallback = true
        this.isLoading = false

        return
      }

      // Check to load image from emulator
      const emulatorServices = process.env.VUE_APP_EMULATORS || ''
      if (emulatorServices === 'all' || emulatorServices.includes('storage')) {
        this.mixGetDownloadURL({ path })
          .then((url) => {
            this.useFallback = false
            this.src = url
          })
          .catch(() => {
            this.isLoading = false
            this.useFallback = true
          })

        return
      }

      const basePath = `${HOST.cloudinary}/${path}`
      let src = `${basePath}?tx=t_${this.transformation}`

      // Default cloudinary tranformation
      if (this.transformation === 'default') {
        src = `${basePath}?tx=t_auto_format_auto_quality`
      }

      // Transform likely to happen only in online
      if (this.transformation === 'overview_account_logo') {
        src = `${basePath}?tx=c_fit,h_55,w_180`
      }

      this.src = src
    },
    onImageError() {
      if (this.context !== 'online') {
        this.removeAsPending(this.context, 'images')
      }

      this.useFallback = true
      this.isLoading = false
      this.$emit('error')
    },
    onImageLoaded() {
      if (this.context !== 'online') {
        this.removeAsPending(this.context, 'images')
      }

      this.isLoading = false
      this.$emit('loaded')
    },
  },
  components: { Spinner },
  created() {
    if (this.context !== 'online') {
      this.addAsPending(this.context, 'images')
    }
  },
  mounted() {
    this.loadImage()
  },
})
