
import OverviewBox from '@/components/overview/OverviewBox.vue'
import { ISamplesBoxData } from '@/__types__/overview'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'SamplesBox',
  props: {
    data: {
      type: Object as PropType<ISamplesBoxData>,
      required: true,
    },
  },
  components: { OverviewBox },
})
