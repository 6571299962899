import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createSlots as _createSlots, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OverviewBox = _resolveComponent("OverviewBox")!

  return (_openBlock(), _createBlock(_component_OverviewBox, {
    class: "RemediationBox",
    link: {
      icon: 'remediation',
      to: 'RemediationTasks',
      title: _ctx.mixWB('VEIW_REMEDIATION_TASKS'),
    }
  }, _createSlots({
    subHeader: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.mixWB('SUMMARY')), 1)
    ]),
    _: 2
  }, [
    (_ctx.data.noTasks)
      ? {
          name: "noData",
          fn: _withCtx(() => [
            _createElementVNode("p", null, _toDisplayString(_ctx.mixWB('NO_REMEDIATION_TASKS')), 1)
          ])
        }
      : {
          name: "list",
          fn: _withCtx(() => [
            _createElementVNode("ul", { class: "ListDisc" }, [
              (_ctx.data.withCleaningText)
                ? (_openBlock(), _createElementBlock("li", { key: 0 }, _toDisplayString(_ctx.data.withCleaningText), 1))
                : _createCommentVNode("", true),
              (_ctx.data.withoutCleaningText)
                ? (_openBlock(), _createElementBlock("li", { key: 1 }, _toDisplayString(_ctx.data.withoutCleaningText), 1))
                : _createCommentVNode("", true),
              (_ctx.data.potentialText)
                ? (_openBlock(), _createElementBlock("li", { key: 2 }, _toDisplayString(_ctx.data.potentialText), 1))
                : _createCommentVNode("", true)
            ])
          ])
        }
  ]), 1032, ["link"]))
}