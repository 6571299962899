import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-94dcc0ac"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "OverviewBox" }
const _hoisted_2 = ["href"]
const _hoisted_3 = {
  key: 0,
  class: "SeparationLine"
}
const _hoisted_4 = { class: "Content" }
const _hoisted_5 = {
  key: 0,
  class: "Meta"
}
const _hoisted_6 = {
  key: 1,
  class: "Caption"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InlineSVG = _resolveComponent("InlineSVG")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_ToolTip = _resolveComponent("ToolTip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _renderSlot(_ctx.$slots, "top", {}, () => [
      (_ctx.link && _ctx.link.external)
        ? (_openBlock(), _createElementBlock("a", {
            key: 0,
            class: "HeaderLink",
            href: _ctx.link.to,
            target: "_blank"
          }, [
            _createVNode(_component_InlineSVG, {
              class: "BoxIcon",
              src: require(`@/assets/svg/${_ctx.link.icon}.svg`)
            }, null, 8, ["src"]),
            _createElementVNode("h2", null, _toDisplayString(_ctx.link.title), 1),
            _createVNode(_component_InlineSVG, {
              class: "Icon",
              src: require('@/assets/svg/external-link.svg')
            }, null, 8, ["src"])
          ], 8, _hoisted_2))
        : (_ctx.link)
          ? (_openBlock(), _createBlock(_component_router_link, {
              key: 1,
              class: "HeaderLink",
              to: { name: _ctx.link.to}
            }, {
              default: _withCtx(() => [
                _createVNode(_component_InlineSVG, {
                  class: "BoxIcon",
                  src: require(`@/assets/svg/${_ctx.link.icon}.svg`)
                }, null, 8, ["src"]),
                _createElementVNode("h2", null, _toDisplayString(_ctx.link.title), 1),
                _createVNode(_component_InlineSVG, {
                  class: "Icon",
                  src: require('@/assets/svg/angle-right.svg')
                }, null, 8, ["src"])
              ]),
              _: 1
            }, 8, ["to"]))
          : _createCommentVNode("", true)
    ], true),
    (_ctx.showSeparationLine)
      ? (_openBlock(), _createElementBlock("hr", _hoisted_3))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_4, [
      _renderSlot(_ctx.$slots, "noData", {}, () => [
        (_ctx.$slots.meta || _ctx.$slots.disclaimer)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createElementVNode("p", null, [
                _renderSlot(_ctx.$slots, "meta", {}, undefined, true)
              ]),
              (_ctx.$slots.disclaimer)
                ? (_openBlock(), _createBlock(_component_ToolTip, {
                    key: 0,
                    class: "Disclaimer",
                    placement: "below"
                  }, {
                    message: _withCtx(() => [
                      _renderSlot(_ctx.$slots, "disclaimer", {}, undefined, true)
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_InlineSVG, {
                        src: require('@/assets/svg/info.svg')
                      }, null, 8, ["src"])
                    ]),
                    _: 3
                  }))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true),
        (_ctx.$slots.subHeader)
          ? (_openBlock(), _createElementBlock("small", _hoisted_6, [
              _renderSlot(_ctx.$slots, "subHeader", {}, undefined, true)
            ]))
          : _createCommentVNode("", true),
        _renderSlot(_ctx.$slots, "list", {}, undefined, true)
      ], true)
    ])
  ]))
}