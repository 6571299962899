
import Backdrop from '@/components/Backdrop.vue'
import { getElementDocumentPos } from '@/globals/javascript/_utils/util'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'ToolTip',
  props: {
    placement: {
      type: String as PropType<'side' | 'below'>,
      required: false,
      default: 'side',
    },
    forceSide: {
      type: String as PropType<'right' | 'left'>,
      required: false,
      default: '',
    },
    color: {
      type: String,
      required: false,
      default: 'white',
    },
    isOnlyClick: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      hoverTimeout: null as any,
      useLeftSide: true,
      showToolTipByHover: false,
      showToolTipByClick: false,
    }
  },
  computed: {
    toolTipClasses() {
      return {
        ColorWhite: this.color === 'white',
        PlacementSide: this.placement === 'side',
        PlacementBelow: this.placement === 'below',
        SideLeft: this.useLeftSide,
        SideRight: !this.useLeftSide,
      }
    },
    hasMessage() {
      return !!this.$slots.message
    },
    showToolTip() {
      if (!this.hasMessage) {
        return false
      }
      if (this.showToolTipByHover) {
        return true
      }
      if (this.showToolTipByClick) {
        return true
      }
      return false
    },
    showBackDrop() {
      return this.showToolTipByClick
    },
  },
  methods: {
    onToolTipClick() {
      if (this.showToolTipByClick) {
        this.showToolTipByClick = false
        return
      }
      this.showToolTipByHover = false
      clearInterval(this.hoverTimeout)
      this.openToolTip()
    },
    onToolTipMouseEnter() {
      if (!this.hasMessage) {
        return
      }

      if (this.isOnlyClick) {
        return
      }

      if (this.showToolTip) {
        return
      }

      this.hoverTimeout = setTimeout(() => {
        this.openToolTip(true)
      }, 100)
    },
    onToolTipMouseLeave() {
      clearTimeout(this.hoverTimeout)
      this.showToolTipByHover = false
    },
    openToolTip(isHover = false) {
      if (isHover) {
        this.showToolTipByHover = true
      } else {
        this.showToolTipByClick = true
      }

      if (this.forceSide) {
        this.useLeftSide = this.forceSide === 'left'
        return
      }

      const toolTipPos = getElementDocumentPos(this.$el)
      this.useLeftSide = true

      // Check to use other side
      requestAnimationFrame(() => {
        const messagePos = getElementDocumentPos(this.$refs.Message)
        const messageMaxWidth = toolTipPos.left - 30
        const messageWidth = messagePos.right - messagePos.left

        if (messageWidth > messageMaxWidth) {
          this.useLeftSide = false
        }
      })
    },
  },
  components: { Backdrop },
})
