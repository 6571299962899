
import ToolTip from '@/components/ToolTip.vue'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'OverviewBox',
  props: {
    link: {
      type: Object as PropType<{
        icon: 'fractions' | 'types' | 'resale' | 'buildings' | 'analysis'
        to: string
        external?: boolean
        title: string
      }>,
      required: false,
    },
    showSeparationLine: {
      type: Boolean,
      default: true,
      required: false,
    },
  },
  components: { ToolTip },
})
